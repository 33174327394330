// src/redux/slices/inputSlice.js
import { createSlice } from '@reduxjs/toolkit';

const inputSlice = createSlice({
  name: 'input',
  initialState: {
    inputValues: {},
    log: [], // Ensure log is initialized to an empty array
  },
  reducers: {
    setInputValue: (state, action) => {
      state.inputValues[action.payload.key] = action.payload.value;
    },
    addLog: (state, action) => {
      state.log.push(action.payload);
    },
  },
});

export const { setInputValue, addLog } = inputSlice.actions;

export default inputSlice.reducer;

