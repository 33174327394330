import React from 'react';
import '../styles/Header.css';
import DarkModeToggle from './DarkModeToggle';
import LoginButton from './LoginButton';
import AflowLogo from './AflowLogo';
//import useScrollDirection from '../hooks/useScrollDirection'; // Ensure correct path


function Header() {
 // const scrollDirection = useScrollDirection();
  return (
    <header className={`header`}>
      <AflowLogo />
      <nav className="nav">
        {/* Render DarkModeToggle in the header */}
        <DarkModeToggle />
        <LoginButton />
      </nav>
    </header>
  );
}

export default Header;
