import React from 'react';
import DataTable from './Components/DataTable';

const JSONOutput = ({ output }) => {
  return (
    <div style={{ marginBottom: '10px' }}>
      <strong>{output.name}:</strong>
      <pre>
        <DataTable jsonData={output.value} />
      </pre>
    </div>
  );
};

export default React.memo(JSONOutput);
