import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sanitizeJsonString, shortenUserId } from '../../../utils/helpers';
import flowIcon from '../../../images/aflow-icon-sq-anim.png';
import { PiArrowBendDownRightFill } from "react-icons/pi";
import JSONOutput from './JSONOutput';
import VisualDataOutput from './VisualDataOutput';
import { updateFlowRun, setCurrentInputRequirement } from '../../../redux/slices/flowSlice';
import { selectFlowRun } from '../../../redux/selectors';
import './styles/chat.css';


const FlowMessage = (props) => {
  const message = props.message || props;
  const { user } = useSelector((state) => state.user);
  const userClass = message.user ? (shortenUserId(message.user.id) === shortenUserId(user.sub) ? 'me' : 'others') : 'system';
  const isFlowMessage = message.user && message.user.id.startsWith('flow-');
  const flowRun = useSelector(selectFlowRun);
  const dispatch = useDispatch();
  const previousCustomTypeRef = useRef();
  const valueToString = (value) => {
    if (typeof value === 'string') {
      return value;
    } else if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value, null, 2); // Pretty print JSON
    } else {
      return String(value); // Convert other types to string
    }
  };
  
  useEffect(() => {
    const previousCustomType = previousCustomTypeRef.current;

    if (message.flow_id && message.flowRun_id) {
      if (message.customType === 'FlowCompleted' &&   previousCustomType !== 'FlowCompleted') {
        dispatch(updateFlowRun({ flowId: message.flow_id, flowRun: { ...flowRun, flowRunId: message.flowRun_id, status: 'completed' } }));
      } 
      else if (message.customType === 'InputRequired'  && previousCustomType !== 'InputRequired') {
        dispatch(updateFlowRun({ flowId: message.flow_id, flowRun: { ...flowRun, flowRunId: message.flowRun_id, status: 'pending' } }));
        const sanitizedStr = sanitizeJsonString(message.text);
        const outputArray = JSON.parse(`[${sanitizedStr}]`);
        const requiredVariables = outputArray[0].inputRequired;
        dispatch(setCurrentInputRequirement({ flowId: message.flow_id, inputRequirement: requiredVariables }));
      } 
      else if (message.customType === 'FlowFailed'  && previousCustomType !== 'FlowFailed') {
        dispatch(updateFlowRun({ flowId: message.flow_id, flowRun: { ...flowRun, flowRunId: message.flowRun_id, status: 'failed' } }));
      }
    }
    previousCustomTypeRef.current = message.customType;
  }, [dispatch, message.customType, message.text, flowRun?.status, message.flow_id, message.flowRun_id]);

  const renderFormattedOutput = (outputArray) => {
    return outputArray.map((output, index) => {
      if (output.type === 'visual_data') {
        return <VisualDataOutput key={index} output={output} attachments={message.attachments} />;
      } else if (output.type === 'json') {
        return <JSONOutput key={index} output={output} />;
      } else {
        return (
          <div key={index} style={{ marginBottom: '10px' }}>
            {/*output.name}:*/}
            {valueToString(output.value)}
          </div>
        );
      }
    });
  };

  const renderContent = () => {
    try {
      if (message.customType === 'message.date') {
        return <p>{new Date(message.date).toLocaleString()}</p>;
      }

      const sanitizedStr = sanitizeJsonString(message.text);
      const outputArray = JSON.parse(`[${sanitizedStr}]`);

      if (message.customType === 'FlowCompleted') {
        return renderFormattedOutput(outputArray);
      } else if (message.customType === 'InputRequired') {
        const requiredVariables = outputArray[0].inputRequired;
        return (
          <>
            <p>Please provide the following:</p>
           
            {requiredVariables.map((input, index) => (
              <div  key={index}  className='message-input'> <PiArrowBendDownRightFill />
              <div style={{marginLeft:'10px', marginBottom: '10px', marginTop:'10px', flexBasis:'row' }}>
                
                <strong> {input} </strong>
              </div>
              </div>
            ))}
          </>
        );
      }
    } catch (error) {
      console.error(`Error parsing ${message.customType} message:`, error);
      return <p>{message.text}</p>;
    }
  };

  const renderMessage = () => {
    if (message.customType === 'FlowFailed') {
      return (
        <div className='message-error' key={message.id}>
          <p>{message.text}</p>
        </div>
      );
    }

    return (
      <div className={`message-${userClass}`} key={message.id}>
        {isFlowMessage ? (
          <img src={flowIcon} alt="Flow Bot" className="avatar" />
        ) : (
          message.user?.image && <img src={message.user.image} alt={message.user.name} className="avatar" />
        )}
        <div className={`message-inner-${userClass}`}>{renderContent() || <p>{message.text}</p>}</div>
      </div>
    );
  };

  return renderMessage();
};

export default React.memo(FlowMessage);
