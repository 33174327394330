import React from 'react';
import DataTable from './Components/DataTable';

const VisualDataOutput = ({ output, attachments }) => {
  const selectedAttachment = attachments.find((attachment) => attachment.title === output.name);

  if (selectedAttachment) {
    const myJson = JSON.parse(atob(selectedAttachment.asset_url.split(',')[1]));
    return (
      <div style={{ marginBottom: '10px' }}>
        <strong>{output.name}</strong>
        <DataTable jsonData={myJson} />
      </div>
    );
  }

  return (
    <div style={{ marginBottom: '10px' }}>
      <strong>{output.name}:</strong>
      <p>{JSON.stringify(output.value)}</p>
    </div>
  );
};

export default React.memo(VisualDataOutput);
