import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    accessToken: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
    },
    setStatus: (state, action) => {
      state.status = action.payload.status;
    },
    setError: (state, action) => {
      state.error = action.payload.error;
    },
  },
});

export const { setUser, setStatus, setError } = userSlice.actions;
export default userSlice.reducer;
