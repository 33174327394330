// Hero.js
import React from 'react';
import '../styles/Hero.css';

function Hero() {
  return (
    <section className="hero">
      <br/>
      <br/>
      
      
      <h1>"The tongue of a man is the rudder of a ship."</h1>
      <p>Homer in The Iliad</p>
      
    </section>
  );
}

export default Hero;
