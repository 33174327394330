import React, { useCallback, useEffect, useRef } from 'react';
import { Chat, Channel, Window, MessageList } from 'stream-chat-react';
import { useStreamClient } from '../../../clients/StreamClient';
import { useDispatch, useSelector } from 'react-redux';
import FlowMessage from './FlowMessage';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import LoadingScreen from '../../../components/LoadingScreen';
import { selectFlow } from '../../../redux/selectors';
import { clearFlowRun } from '../../../redux/slices/flowSlice';
import FlowInfo from './Components/FlowInfo';  // Import the new component

import './styles/OutputArea.css';
import './styles/chat.css';

const OutputArea = React.memo(({ isLogVisible, setIsLogVisible }) => {
  const flow = useSelector(selectFlow);
  const { chatClient, channel, loading } = useStreamClient();
  const messagesEndRef = useRef(null);
  const dispatch = useDispatch();

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const customRenderMessages = useCallback((options) => {
    const elements = options.messages.map((message) => (
      <CSSTransition key={message.id} timeout={800} classNames="message">
        <li>
          <FlowMessage message={message} />
        </li>
      </CSSTransition>
    ));

    return (
      <>
        <TransitionGroup component="ul">{elements}</TransitionGroup>
        <div ref={messagesEndRef} />
      </>
    );
  }, []);

  const clearMessages = async () => {
    try {
      if (channel) {
        await channel.truncate();
        console.log('Channel truncated');
      }
    } catch (error) {
      console.error('Error truncating channel:', error);
    }
    dispatch(clearFlowRun({ flowId: flow.id }));
    console.log('Clear messages button clicked');
  };

  const toggleLogVisibility = () => {
    setIsLogVisible(!isLogVisible);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsLogVisible(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [setIsLogVisible]);

  useEffect(() => {
    scrollToBottom();
  }, [channel]);

  if (!flow) return <LoadingScreen message="Please select a flow..." />;
  if (loading || !channel) return <LoadingScreen message="Getting things ready..." />;

  return (
    <div className="output-area">
      <FlowInfo 
        flow={flow} 
        clearMessages={clearMessages} 
        toggleLogVisibility={toggleLogVisibility} 
      />
      <Chat client={chatClient} theme="str-chat__theme-light">
        <Channel channel={channel}>
          <Window>
            <MessageList renderMessages={customRenderMessages} />
          </Window>
        </Channel>
      </Chat>
    </div>
  );
});

export default OutputArea;
