import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from './Components/Sidebar';
import Header from './Components/Header';
import './Styles/UserArea.css';
import { syncUserWithDatabase } from '../services/userService';
import { useAuth0 } from '@auth0/auth0-react';
import FlowInterface from './Components/FlowInterface';
import { setUser, setStatus, setError } from '../redux/slices/userSlice';

import { StreamClientProvider } from '../clients/StreamClient';
import LoadingScreen from '../components/LoadingScreen';

//const HARDCODED_FLOW_ID = '72c1820a-eefa-451e-9c0e-dd3145d7bcd7'; // Replace with actual flow ID

function UserArea() {
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const dispatch = useDispatch();
    const { isAuthenticated, getAccessTokenSilently, user: auth0User, isLoading } = useAuth0();
    const { user, accessToken, status } = useSelector((state) => state.user);

    const toggleSidebar = () => setIsSidebarVisible(!isSidebarVisible);

    useEffect(() => {
        const fetchAndSetUser = async () => {
            if (isAuthenticated && auth0User && (!user || !accessToken)) {
                try {
                    dispatch(setStatus({ status: 'loading' }));
                    const accessToken = await getAccessTokenSilently({
                        audience: 'https://www.aflow.ai/api',
                        scope: 'readApi'
                    });
                    await syncUserWithDatabase(auth0User, accessToken);
                    dispatch(setUser({ user: auth0User, accessToken }));
                    dispatch(setStatus({ status: 'succeeded' }));
                } catch (error) {
                    console.error('Error syncing user with database:', error);
                    dispatch(setError({ error: error.message }));
                    dispatch(setStatus({ status: 'failed' }));
                }
            }
        };

        fetchAndSetUser();
    }, [isAuthenticated, auth0User, user, accessToken, getAccessTokenSilently, dispatch]);

    
    if (!isAuthenticated) {
        return <LoadingScreen message="Please log in to access this area."  />;
      }
    
      if (isLoading || status === 'loading') {
        return <LoadingScreen message="Getting things ready..."  />;
      }
    
      if (status === 'failed') {
        return <LoadingScreen message='Error loading user data: ${error}'  />;     
      }
    

    return (
        <StreamClientProvider>
            <div className="user-area">
                <Header toggleSidebar={toggleSidebar} />
                <div className="container">
                <Sidebar className="sidebar" visible={isSidebarVisible} onLinkClick={toggleSidebar} />
                <FlowInterface />
                </div>
            </div>
        </StreamClientProvider>
    );
}

export default UserArea;
