// src/components/LogoutButton.js
import React from 'react';
import { MdOutlineLogout } from 'react-icons/md';
import { useAuth0 } from '@auth0/auth0-react';
import '../../styles/Button.css';

function LogoutButton() {
  const { logout, isAuthenticated } = useAuth0(); // Check authentication

  if (!isAuthenticated) return null; // Don't show if not authenticated

  return (
    <button href="#logout" onClick={() => logout({ returnTo: window.location.origin })} className="btn">
      <MdOutlineLogout /> {/* Icon */}
      
    </button>
  );
}

export default LogoutButton;
