// src/redux/slices/flowRunSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { initializeFlowRun, startFlowRun, resumeFlowRun } from '../../services/flowService';

export const initializeRun = createAsyncThunk(
  'flowRun/initializeRun',
  async ({ flowId, inputValues, userId, accessToken }) => {
    const response = await initializeFlowRun(flowId, inputValues, userId, accessToken);
    return response;
  }
);

export const startRun = createAsyncThunk(
  'flowRun/startRun',
  async ({ flowRunId, accessToken }) => {
    const response = await startFlowRun(flowRunId, accessToken);
    return response;
  }
);

export const resumeRun = createAsyncThunk(
  'flowRun/resumeRun',
  async ({ flowRunId, inputValues, userId, accessToken }) => {
    const response = await resumeFlowRun(flowRunId, inputValues, userId, accessToken);
    return response;
  }
);

const flowRunSlice = createSlice({
  name: 'flowRun',
  initialState: {
    flowRun: null,
    status: 'idle',
    currentInputRequirement: null,
  },
  reducers: {
    updateFlowRun: (state, action) => {
      state.flowRun = action.payload;
    },
    setFlowRunStatus: (state, action) => {
      if (state.flowRun) {
        state.flowRun.status = action.payload;
      }
    },
    setCurrentInputRequirement: (state, action) => {
      state.currentInputRequirement = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeRun.fulfilled, (state, action) => {
        state.flowRun = action.payload;
        state.status = 'succeeded';
        state.currentInputRequirement = 'Flow is running...';
      })
      .addCase(startRun.fulfilled, (state, action) => {
        state.flowRun = { ...state.flowRun, ...action.payload };
        state.status = 'idle';
      })
      .addCase(resumeRun.fulfilled, (state, action) => {
        state.flowRun = action.payload;
        state.status = 'succeeded';
      });
  },
});

export const { updateFlowRun, setFlowRunStatus, setCurrentInputRequirement } = flowRunSlice.actions;

export default flowRunSlice.reducer;
