// src/components/LandingPage.js
import React from 'react';
import Header from './Header';
import Hero from './Hero';

function LandingPage({ darkMode, setDarkMode }) {
  return (
    <div>
      <Header darkMode={darkMode} setDarkMode={setDarkMode} />
      <Hero />
    </div>
  );
}

export default LandingPage;
