import React from 'react';

const renderJson = (data, level = 0) => {
  /*  const indent = (level) => {
        return Array(level).fill('\u00A0\u00A0').join('');
    };*/

    if (typeof data !== 'object' || data === null) {
        return <span>{data}</span>;
    }

    return (
        <div>
            {Object.keys(data).map((key) => (
                <div key={key} style={{ marginLeft: level * 20 }}>
                   {/* <strong>{indent(level)}{key}:</strong>{' '}*/ }
                    {typeof data[key] === 'object' ? renderJson(data[key], level + 1) : <span>{data[key]}</span>}
                </div>
            ))}
        </div>
    );
};

export default renderJson;
