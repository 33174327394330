import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchSpaces as fetchSpacesFromService } from '../../services/spaceService';

export const fetchSpaces = createAsyncThunk(
  'space/fetchSpaces',
  async (accessToken) => {
    const response = await fetchSpacesFromService(accessToken);
    return response;
  }
);

const spaceSlice = createSlice({
  name: 'space',
  initialState: {
    spaces: [],
    selectedSpace: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    setSelectedSpace: (state, action) => {
      state.selectedSpace = action.payload;
    },
    setSpaces: (state, action) => {
      state.spaces = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSpaces.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSpaces.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.spaces = action.payload;
      })
      .addCase(fetchSpaces.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { setSelectedSpace, setSpaces } = spaceSlice.actions;

export default spaceSlice.reducer;
