import { StreamChat } from 'stream-chat';
import {
  Chat,
  Channel as StreamChannel,
  ChannelHeader,
  MessageList,
  MessageInput,
  Window,
} from 'stream-chat-react';
import 'stream-chat-react/dist/css/index.css';
import { useSelector } from 'react-redux';
import axios from 'axios';
import React, { createContext, useContext, useState, useEffect, useRef } from 'react';

const chatClient = StreamChat.getInstance(process.env.REACT_APP_STREAM_API_KEY);

const StreamClientContext = createContext();

export const useStreamClient = () => {
  return useContext(StreamClientContext);
};

export const StreamClientProvider = ({ children }) => {
  const { user, accessToken } = useSelector((state) => state.user);
  const [channel, setChannel] = useState(null);
  const [loading, setLoading] = useState(true); // Track loading state
  const connectUserCalled = useRef(false); // Track if connectUser has been called

  useEffect(() => {
    const connectUser = async () => {
      try {
        if (!user || !accessToken || connectUserCalled.current) return;

        connectUserCalled.current = true; // Set to true to prevent multiple calls

        const validUserId = user.sub.replace(/[^a-zA-Z0-9-_@]/g, ''); // Sanitize user.sub to conform to Stream's requirements

        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/generate-stream-token`, { userId: validUserId }, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const streamToken = response.data.token;
        await chatClient.connectUser(
          {
            id: validUserId,
            name: user.name,
            image: user.picture,
          },
          streamToken
        );
        console.log('Connected to Stream:', chatClient.user);
        setLoading(false); // Set loading to false after user is connected
      } catch (error) {
        console.error('Error connecting to Stream:', error);
        setLoading(false); // Set loading to false even on error
      }
    };

    connectUser();

    return () => {
      if (chatClient) {
        chatClient.disconnectUser();
      }
    };
  }, [user, accessToken]);

  return (
    <StreamClientContext.Provider value={{ chatClient, channel, setChannel, loading, isUserConnected: !loading }}>
      {children}
    </StreamClientContext.Provider>
  );
};

export { Chat, StreamChannel, ChannelHeader, MessageList, MessageInput, Window };
