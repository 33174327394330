import React, { useEffect } from 'react';
import { Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import LandingPage from './components/LandingPage';
import LoadingScreen from './components/LoadingScreen';
import UserArea from './userarea/UserArea';
import FlowInterface from './userarea/Components/FlowInterface';
import './styles/App.css';
import { useAuth0 } from "@auth0/auth0-react";
import store from './redux/store';
import { setTheme } from './redux/slices/themeSlice'; // Import the setTheme action
import { applyTheme } from './utils/themeUtils';
import { selectTheme } from './redux/selectors'; // Import the theme selector

const ProtectedUserArea = withAuthenticationRequired(UserArea, {
  onRedirecting: () => <LoadingScreen message="Getting things ready..."  />, // Optional: Provide a loading state
});

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme') || 'light';
    dispatch(setTheme(storedTheme)); // Set the theme from local storage
    applyTheme(storedTheme);
  }, [dispatch]);

  const darkMode = useSelector(selectTheme) === 'dark'; // Use the theme selector
  const { error } = useAuth0();

  useEffect(() => {
    applyTheme(darkMode ? 'dark' : 'light');
  }, [darkMode]);

  if (error) {
    console.error('Auth0 Error:', error);
  }

  return (
    <Provider store={store}>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.origin + "/userarea",
          audience: "https://www.aflow.ai/api",
          scope: "openid profile email readApi write:users read:users"
        }}
      >
        <Router>
          <div className="App">
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/userarea/*" element={<ProtectedUserArea />}>
                <Route index element={<FlowInterface />} />
                <Route path="space/:spaceId/flow/:flowId" element={<FlowInterface />} />
              </Route>
            </Routes>
          </div>
        </Router>
      </Auth0Provider>
    </Provider>
  );
}

export default App;
