import React from 'react';
import { useSelector } from 'react-redux';
import '../styles/Header.css';
import logoDark from '../images/aflow-dark-slk.png';
import logoLight from '../images/aflow-light-slk.png';


function AflowLogo() {
  const darkMode = useSelector(state => state.theme.value === 'dark');  // Check if the current theme is 'dark'

  return (
   
      <div className="logo">
        {/* Display the appropriate logo based on the darkMode */}
        <img
          src={darkMode ? logoDark : logoLight}
          alt="Logo"
        />
      </div>
    
  );
}

export default AflowLogo;
