import React from 'react';
import { useSelector } from 'react-redux';
import renderJson from '../../../utils/renderJson'; // Adjust the path as necessary
import { PiXSquareThin } from "react-icons/pi";

const FlowLog = ({ isLogVisible, setIsLogVisible }) => {
  const log = useSelector((state) => state.input.log); // Ensure selecting from the correct slice

  const toggleLogVisibility = () => {
    setIsLogVisible(!isLogVisible);
  };

  return (
    <div>
      <div className="flowlog-ui">
        <PiXSquareThin onClick={toggleLogVisibility} />
      </div>
      <div className="flowlog-content">
        <h4>Activity Log</h4>
        {log.map((entry, index) => (
          <div key={index}>
            {/* <p><strong>Event Type:</strong> {entry.eventType}</p> */}
            <div>{renderJson(entry)}</div>
            <hr />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FlowLog;
