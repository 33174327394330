import React from 'react';
import '../styles/App.css';

const LoadingScreen = ({ message }) => {
  return (
    <div className="loading-screen" >
      <div className="loading-message">
        {message} </div>
        <br/>
        <div className="loading-spinner"></div>
     
    </div>
  );
};

export default LoadingScreen;
