import React from 'react';
import { Handle, Position } from 'reactflow';

const StepNode = ({ data }) => {
  const { name, handler, inputs=[], output } = data;

  return (
    <div className="step-node">
      <div className="step-node-header">
        <h4>{name}</h4>
        <p>Step type: {handler}</p>
      </div>
      <div className="step-node-body">
        <p><strong>Inputs:</strong> {inputs.join(', ')}</p>
        <p><strong>Output:</strong> {output.join(', ')}</p>
      </div>
      <Handle type="target" position={Position.Left} />
      <Handle type="source" position={Position.Right} />
    </div>
  );
};

const VariableNode = ({ data }) => {
  const { name, type, value, isInput, isOutput } = data;

  return (
    <div className={`variable-node ${isInput ? 'input-variable' : ''} ${isOutput ? 'output-variable' : ''}`}>
      <div className="variable-node-header">
        <h4>{name}</h4>
      </div>
      <div className="variable-node-body">
        <p><strong>Type:</strong> {type}</p>
        {value && <p><strong>Default Value:</strong> {value}</p>}
      </div>
      <Handle type="target" position={Position.Left} />
      <Handle type="source" position={Position.Right} />
    </div>
  );
};

export { StepNode, VariableNode };
