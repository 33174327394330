import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { PiCaretCircleDoubleRightFill } from "react-icons/pi";
import { setInputValue, addLog } from '../../../redux/slices/inputSlice';
import { initializeRun, startRun, resumeRun, setCurrentInputRequirement, updateFlowRun } from '../../../redux/slices/flowSlice';
import { selectInput, selectFlowRun, selectFlow, selectSelectedSpace, selectInputValues, selectStatus, selectCurrentInputRequirement, selectFlowRunStatus } from '../../../redux/selectors';
import { useStreamClient } from '../../../clients/StreamClient';
import './styles/InputArea.css';

function InputArea() {
    const dispatch = useDispatch();
    const { getAccessTokenSilently, user } = useAuth0();
    const inputRef = useRef(null);
    const { channel } = useStreamClient();
    const flow = useSelector(selectFlow);
    const spaceid = useSelector(selectSelectedSpace);
    const input = useSelector(selectInput);
    const flowRun = useSelector(selectFlowRun);
    const inputValues = useSelector(selectInputValues);
    const status = useSelector(selectStatus);
    const currentInputRequirement = useSelector(selectCurrentInputRequirement);
    const flowRunStatus = useSelector(selectFlowRunStatus);

    useEffect(() => {
        inputRef.current.focus();
        autoResizeTextarea();
    }, []);

    useEffect(() => {
        const isEmptyObject = (obj) => {
            return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
        };

        if ((currentInputRequirement === null || isEmptyObject(currentInputRequirement)) && flow?.configuration?.inputVariables) {
            dispatch(setCurrentInputRequirement({ flowId: flow.id, inputRequirement: flow.configuration.inputVariables }));
        }
    }, [flow, flowRun, currentInputRequirement, dispatch]);

    const autoResizeTextarea = () => {
        const textarea = inputRef.current;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    const handleInputChange = (value) => {
        dispatch(setInputValue({ key: 'command', value }));
        autoResizeTextarea();
    };

    const handleSubmit = async (event) => {
        inputRef.current.focus();
        event.preventDefault();
        if (!flow) return;
        const command = input.trim();
        if (!command) return;

        try {
            await sendMessageToChannel(channel, command);

            dispatch(setInputValue({ key: 'command', value: '' }));
            dispatch(addLog(`Executing command: ${command}`));

            const accessToken = await getAccessTokenSilently({
                audience: 'https://www.aflow.ai/api',
                scope: 'readApi',
            });

            const inputKey = currentInputRequirement || flow.configuration.inputVariables || 'command';
            const updatedInputValues = { ...inputValues, [inputKey]: command, spaceId: spaceid.value };

            if (flowRunStatus === 'pending') {
                const resumeResult = await dispatch(resumeRun({ flowRunId: flowRun.flowRunId, inputValues: updatedInputValues, userId: user.sub, accessToken })).unwrap();
                dispatch(addLog(`Flow run resumed with ID: ${resumeResult.flowRunId}`));
                dispatch(updateFlowRun({ flowId: flow.id, flowRun: resumeResult }));
                await sendMessageToChannel(channel, 'Flow run resumed');
            } else {
                const initializeResult = await dispatch(initializeRun({ flowId: flow.id, inputValues: updatedInputValues, userId: user.sub, accessToken })).unwrap();
                dispatch(addLog(`Flow run started with ID: ${initializeResult.flowRunId}`));
                dispatch(updateFlowRun({ flowId: flow.id, flowRun: initializeResult }));

                await sendMessageToChannel(channel, 'New flow run started');
                await dispatch(startRun({ flowRunId: initializeResult.flowRunId, accessToken })).unwrap();
            }
        } catch (error) {
            dispatch(addLog(`Error: ${error.message}`));
        }
    };

    const inputRequirementString = Array.isArray(currentInputRequirement)
        ? currentInputRequirement.join(', ')
        : typeof currentInputRequirement === 'object'
        ? JSON.stringify(currentInputRequirement)
        : currentInputRequirement;

    return (
        <form onSubmit={handleSubmit} className="input-area">
            <textarea
                value={input}
                ref={inputRef}
                onChange={(e) => handleInputChange(e.target.value)}
                placeholder={inputRequirementString || null}
                className="input-field"
                rows="4"
                disabled={status === 'loading'}
                onInput={autoResizeTextarea}
            />
            <button type="submit" className="submit-button" disabled={status === 'loading'}>
                <PiCaretCircleDoubleRightFill className="submit-button-text" />
            </button>
        </form>
    );
}

export default InputArea;

async function sendMessageToChannel(channel, message) {
    try {
        await channel.sendMessage({
            text: message,
            customType: 'text',
        });
    } catch (error) {
        console.error('Error sending message to channel:', error);
    }
}
