
const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? 'var(--primary-bg-dark)' : 'var(--primary-bg-dark)',
    borderColor: state.isFocused ? 'var(--border-dark)' : 'var(--border-dark)',
    boxShadow: state.isFocused ? '0 0 0 1px var(--primary-bg-light)' : null,
    '&:hover': {
      borderColor: state.isFocused ? 'var(--primary-bg-light)' : 'var(--border-dark)',
    },
    color: 'var(--icon-dark)',
    fontSize: '0.8rem',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: '1px solid var(--border-dark)', // Only the bottom border is visible
    borderRadius: '0',
    overflow: 'scroll',
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: 'var(--primary-bg-light)',
    color: 'var(--icon-dark)',
    zIndex: 9999, // Ensures the menu appears above other elements
    overflow: 'scroll',
    top: '30px',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'var(--primary-bg-light)' : 'var(--primary-bg-light)',
    '&:hover': {
      backgroundColor: 'var(--primary-text-dark)',
      overflow: 'visible',
      fontSize: '1.2rem',
    },
    color: state.isSelected ? 'var(--accent-color-dark)' : 'var(--primary-text-light)',
    '&:hover': {
      color: 'var(--accent-color-dark)',
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'var(--primary-text-dark)',
  }),
  input: (provided) => ({
    ...provided,
    color: 'var(--primary-text-dark)',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--border-dark)',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: 'var(--primary-text-dark)',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: 'var(--primary-bg-dark)',
    color: 'transparent',
    display: 'none',
  }),
  
};

export default selectStyles;
