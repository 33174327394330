import React, { useState } from 'react';
import { PiChatSlashThin, PiSquareHalfLight, PiEye, PiEyeSlash } from 'react-icons/pi';
import FlowRunStatusIndicator from './FlowRunStatusIndicator';
import FlowVisualizer from './FlowVisualizer';
import { selectFlow } from '../../../../redux/selectors';
import { useSelector } from 'react-redux';
import { ReactFlowProvider } from 'reactflow';

const FlowInfo = ({ clearMessages, toggleLogVisibility }) => {
  const [showVisualizer, setShowVisualizer] = useState(false);
  const flow = useSelector(selectFlow);
  if (!flow) return null;

  const toggleVisualizerVisibility = () => {
    setShowVisualizer(!showVisualizer);
  };

  return (
    <div className="flow-info-container">
      {flow && (
        <div className="flow-info">
          <div className="flow-info-header">
            <div className="flow-info-text">
              <h2>{flow.name}</h2>
              <p>{flow.description}</p>
            </div>
            <FlowRunStatusIndicator />

            <button onClick={clearMessages} title="Clear Messages">
              <PiChatSlashThin size={24} />
            </button>
            <button onClick={toggleLogVisibility} title="Toggle Log Visibility">
              <PiSquareHalfLight size={24} />
            </button>
            <button className="visualiserToggle" onClick={toggleVisualizerVisibility} title="Toggle Visualizer">
              {showVisualizer ? <PiEyeSlash size={24} /> : <PiEye size={24} />}
            </button>
          </div>
          <ReactFlowProvider>
          <div className={showVisualizer ? 'flow-info-visualizer' : 'flow-info-visualizer-hidden'}>
            
              <FlowVisualizer flowConfig={flow.configuration} />
            
          </div>
          </ReactFlowProvider>
        </div>
      )}
    </div>
  );
};

export default FlowInfo;
