import React from 'react';
import { useSelector } from 'react-redux';
import { selectFlowRun } from '../../../../redux/selectors';
import '../styles/OutputArea.css';

const FlowRunStatusIndicator = () => {
    const flowRun = useSelector(selectFlowRun);
    const flowRunStatus = flowRun?.status || 'undefined';
    console.log('Flow run status:', flowRunStatus);

    const getStatusColor = (status) => {
        switch (status) {
            case 'running':
                return 'white';
            case 'pending':
                return 'yellow';
            case 'failed':
                return 'red';
            case 'completed':
                return 'green';
            default:
                return 'grey';
        }
    };

    return (
        <div className="flow-run-status-indicator" style={{ backgroundColor: getStatusColor(flowRunStatus) }}></div>
    );
};

export default FlowRunStatusIndicator;
