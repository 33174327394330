import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputArea from './InputArea';
import FlowLog from './FlowLog';
import OutputArea from './OutputArea';
import './styles/FlowInterface.css';
import { addLog } from '../../../redux/slices/inputSlice';
import LoadingScreen from '../../../components/LoadingScreen';
import { selectFlow, selectStatus } from '../../../redux/selectors';
import { useStreamClient } from '../../../clients/StreamClient';
import { generateChannelId, sanitizeUserId } from '../../../utils/helpers';

function FlowInterface() {
  const dispatch = useDispatch();
  const { accessToken, user } = useSelector((state) => state.user);
  const flow = useSelector(selectFlow);
  const status = useSelector(selectStatus);
  const { chatClient, setChannel, isUserConnected } = useStreamClient();
  const [isLogVisible, setIsLogVisible] = useState(false);

  useEffect(() => {
    if (flow && user && accessToken && isUserConnected) {
      const sanitizedUserId = sanitizeUserId(user.sub);
      const chanID = generateChannelId(flow.id, user.sub);

      const connectToChannel = async () => {
        const newChannel = chatClient.channel('messaging', chanID, {
          members: [sanitizedUserId],
          own_capabilities: ['read', 'write', 'join', 'add-members', 'AddOwnChannelMembership'],
          extra_data: { virtual_user_role: 'virtualUser-Flow' },
        });
        await newChannel.watch();
        setChannel(newChannel);
        console.log('Connected to channel:', newChannel);
        dispatch(addLog(`Connected to channel with ID: ${chanID}`));
      };

      connectToChannel();
      dispatch(addLog(`Loading flow with ID: ${flow.name}`));
    }
  }, [flow, user, accessToken, isUserConnected, chatClient, setChannel, dispatch]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsLogVisible(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  

  if (status === 'loading') {
    return <LoadingScreen message="Getting things ready..." />;
  }

  if (status === 'failed') {
    return <LoadingScreen message="Loading flow failed." />;
  }

  return (
    <div className="flow-interface">
      <div className="top-container">
        <OutputArea isLogVisible={isLogVisible} setIsLogVisible={setIsLogVisible} />
        <InputArea />
        <div className={`flowlog ${isLogVisible ? '' : 'hidden'}`}>
          <FlowLog isLogVisible={isLogVisible} setIsLogVisible={setIsLogVisible} />
        </div>
      </div>
    </div>
  );
}

export default FlowInterface;
