import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchFlowDetails, initializeFlowRun, startFlowRun, resumeFlowRun, fetchFlows as fetchFlowsFromService } from '../../services/flowService';

export const fetchFlow = createAsyncThunk(
  'flow/fetchFlow',
  async ({ flowId, accessToken }) => {
    const response = await fetchFlowDetails(flowId, accessToken);
    return response;
  }
);

export const initializeRun = createAsyncThunk(
  'flow/initializeRun',
  async ({ flowId, inputValues, userId, accessToken }) => {
    const response = await initializeFlowRun(flowId, inputValues, userId, accessToken);
    return response;
  }
);

export const startRun = createAsyncThunk(
  'flow/startRun',
  async ({ flowRunId, accessToken }) => {
    const response = await startFlowRun(flowRunId, accessToken);
    return response;
  }
);

export const resumeRun = createAsyncThunk(
  'flow/resumeRun',
  async ({ flowRunId, inputValues, userId, accessToken }) => {
    const response = await resumeFlowRun(flowRunId, inputValues, userId, accessToken);
    return response;
  }
);

export const fetchFlows = createAsyncThunk(
  'flow/fetchFlows',
  async ({ spaceId, accessToken }) => {
    const response = await fetchFlowsFromService(spaceId, accessToken);
    return response;
  }
);

const flowSlice = createSlice({
  name: 'flow',
  initialState: {
    flow: null,
    flows: [],
    flowRuns: {}, // Each flow's run state will be stored here
    status: 'idle',
    error: null,
    selectedFlow: null,
    currentInputRequirement: null,
  },
  reducers: {
    setSelectedFlow: (state, action) => {
      state.selectedFlow = action.payload;
    },
    setFlowRunStatus: (state, action) => {
      const { flowId, status } = action.payload;
      if (!state.flowRuns[flowId]) state.flowRuns[flowId] = {};
      state.flowRuns[flowId].status = status;
    },
    setCurrentInputRequirement: (state, action) => {
      const { flowId, inputRequirement } = action.payload;
      if (!state.flowRuns[flowId]) state.flowRuns[flowId] = {};
      state.flowRuns[flowId].currentInputRequirement = inputRequirement;
    },
    updateFlowRun: (state, action) => {
      const { flowId, flowRun } = action.payload;
      state.flowRuns[flowId] = flowRun;
    },
    clearFlowRun: (state, action) => {
      const { flowId } = action.payload;
      delete state.flowRuns[flowId];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFlow.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFlow.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.flow = action.payload;
      })
      .addCase(fetchFlow.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchFlows.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFlows.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.flows = action.payload;
      })
      .addCase(fetchFlows.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(initializeRun.fulfilled, (state, action) => {
        const { flowId, flowRun } = action.payload;
        state.flowRuns[flowId] = { ...state.flowRuns[flowId], ...flowRun, status: 'initialized' };
      })
      .addCase(startRun.fulfilled, (state, action) => {
        const { flowId, flowRun } = action.payload;
        state.flowRuns[flowId] = { ...state.flowRuns[flowId], ...flowRun, status: 'running' };
      })
      .addCase(resumeRun.fulfilled, (state, action) => {
        const { flowId, flowRun } = action.payload;
        state.flowRuns[flowId] = { ...state.flowRuns[flowId], ...flowRun, status: 'running' };
      });
  }
});

export const { setSelectedFlow, setFlowRunStatus, setCurrentInputRequirement, updateFlowRun, clearFlowRun } = flowSlice.actions;

export default flowSlice.reducer;
