// src/utils/themeUtils.js

export const applyTheme = (theme) => {
    document.body.className = theme === 'dark' ? 'dark-mode' : 'light-mode';
  
    const themeColors = theme === 'dark' ? {
      '--primary-bg-dark': '#2f2f2f',
      '--primary-text-dark': '#e0e0e0',
      '--accent-color-dark': '#FF4B4B',
      '--button-bg-dark': '#333',
      '--seconodary-bg-dark': '#333',
      '--button-text-dark': '#f5f5f5',
      '--link-color-dark': '#333',
      '--border-dark': '#666',
      '--border-data-dark': '#fff'
    } : {
      '--primary-bg-dark': '#fff', //'#f1f1f1',
      '--primary-text-dark': '#333333',
      '--accent-color-dark': '#FF4B4B',
      '--button-bg-dark': '#333',
      '--seconodary-bg-dark': '#eee',
      '--button-text-dark': '#4f4f4f',
      '--link-color-dark': '#333',
      '--border-dark': '#000',//'#ccc'
      '--border-data-dark': '#000'
    };
  
    Object.keys(themeColors).forEach(key => {
      document.body.style.setProperty(key, themeColors[key]);
    });
  };
  