// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import themeReducer from './slices/themeSlice';
import flowReducer from './slices/flowSlice'; 
import flowRunReducer from './slices/flowRunSlice';
import inputReducer from './slices/inputSlice';
import userReducer from './slices/userSlice';
import spaceReducer from './slices/spaceSlice'; 

export const store = configureStore({
  reducer: {
    theme: themeReducer,
    flow: flowReducer,
    flowRun: flowRunReducer,
    input: inputReducer,
    user: userReducer,
    space: spaceReducer,
  },
});

export default store;
