import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';

const Root = styled('div')({
  '& .MuiDataGrid-root': {
    backgroundColor: 'transparent',
    color: 'var(--primary-text-light)',
    borderColor: 'var(--border-data-dark)', 
  },
  '& .MuiDataGrid-cell': {
    color: 'var(--primary-text-dark)',
    borderColor: 'var(--border-data-light)',
    whiteSpace: 'wrap',
    overflow: 'visible',
    textOverflow: 'ellipsis',
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#000',
    color: 'var(--primary-text-light)',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: 'var(--seconodary-bg-dark)',
    color: 'var(--primary-text-light)',
  },
  '& .MuiTablePagination-root': {  
    color: 'var(--border-dark)',
    borderColor: 'var(--border-data-dark)',
  },
  '& .MuiTablePagination-actions': {  
    color: 'var(--border-dark)',
  },
  '& .MuiDataGrid-sortIcon, & .MuiDataGrid-menuIcon, & .MuiTablePagination-actions .svg': {
    color: 'var(--accent-color-dark)',
  },
  '& .MuiDataGrid-menuIconButton .MuiSvgIcon-root': {
    color: 'var(--primary-bg-dark)',
  },
});

const DataTable = ({ jsonData, defaultPageSize = 5, rowHeight = 52 }) => {
  let jsonObject  = {};
  try {
    jsonObject = typeof jsonData === 'object' && jsonData !== null ? Object(jsonData) : Object(JSON.parse(jsonData));
  } catch (error) { 
    return <div>{jsonData}</div>; 
  }

  if (!Array.isArray(jsonObject) || jsonObject.length === 0) {
    return <div>No data available: {JSON.stringify(jsonObject)}</div>;
  }

  const sanitizedData = jsonObject.map((item, index) => ({
    id: index, ...item,
  }));

  const columns = Object.keys(sanitizedData[0]).map(key => ({
    field: key,
    headerName: key.charAt(0).toUpperCase() + key.slice(1),
    width: 150,
    renderCell: (params) => {
      const value = params.value;
      const displayValue = typeof value === 'object' && value !== null ? JSON.stringify(value) : value;

      return (
        <Tooltip title={displayValue} arrow>
          <span>{displayValue}</span>
        </Tooltip>
      );
    },
  }));

  // Calculate dynamic height
  const gridHeight = (sanitizedData.length * rowHeight + 110) - (sanitizedData.length <= defaultPageSize ? rowHeight : 0); // 110 is the approximate height for header and footer

  return (
    <Root style={{ height: gridHeight, width: '100%' }}>
      <DataGrid
        columns={columns}
        rows={sanitizedData}
        pageSize={defaultPageSize}
        rowsPerPageOptions={[]}
        hideFooter={sanitizedData.length <= defaultPageSize}
        rowHeight={rowHeight}
      />
    </Root>
  );
};

export default DataTable;
