// src/services/spaceService.js
import axios from 'axios';



const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000/api';

const api = axios.create({
    baseURL: API_BASE_URL
});

export async function fetchSpaces(accessToken) {
    try {
        const response = await api.get('/spaces', {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch spaces:', error);
        throw error;
    }
}

export async function fetchSpaceFlows(spaceId, accessToken) {
    try {
        const response = await api.get(`/spaces/${spaceId}/flows`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch space flows:', error);
        throw error;
    }
}
